<template>
  <assign-group-dialog ref="assignMission">
    </assign-group-dialog>
  
    
</template>

<script>
import AssignGroupDialog from '@/components/AssignGroupDialog.vue'
 
  export default {
    name: 'selectGroup',
    components: {
      AssignGroupDialog,
    },
    data() {
      return {};
    },
    methods: {
    },
    mounted() {
    }
  };
</script>

<style>
</style>
