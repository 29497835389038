<template>
  <default-dialog
    :show-dialog="show"
    width="716px"
    :title="$t('header.selectLocatoin')"
    :next-button-text="$t('button.done')"
    :center="true"
    :has-cancel="false"
    :show-close="false"
    :disable-escaped="true"
    @close="close"
    @next="assignMission"
  >
    <template slot="main">
      <div class="group-selection">
        <label v-if="!preSelectLocation">{{ $t('header.selectLocatoin') }}</label>
        <div class="missions">
          <div
            v-for="(mission, index) in locations"
            :key="`location-${index}`"
            :class="`mission ${(select.mission == mission.id) ? 'activate' : ''} }`"
            @click="selectMission(mission.id)"
          >
            <data-table-thumbnail
              height="83px"
              :value="mission"
            />
            <span class="name">{{ mission.name }}</span>
            <img
              class="select"
              src="/asserts/icon/loadmission-selected-icon.svg"
            >
          </div>
        </div>
      </div>
    </template>
  </default-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FormatMissionPeriod } from '@/utils/mission.js';
import DefaultDialog from '@/components/DefaultDialog.vue';
import DataTableThumbnail from '@/components/DataTable/Thumbnail.vue';

export default {
  name: 'AssignMissionDialog',
  components: {
    DefaultDialog,
    DataTableThumbnail,
  },

  props: {
    preSelectLocation: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      periodUnit: this.$store.state.mission.periodUnit,
      show: true,
      locations: [],
      select: {
        mission: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: 'user/user',
      groups: 'user/groups',
      group: 'user/group',
    }),

    groupId: {
      ...mapGetters({
        get: 'user/groupId',
      }),
      ...mapActions({
        set: 'user/setGroupId',
      }),
    },
  },
  watch: {
    preSelectLocation(now) {
      // console.log("pre selection change", now, old);
      this.locations = [now];
    },
    locations(now) {
      if (now != null && now.length == 1) {
        this.select.mission = this.locations[0].id;
      }
    },
  },
  mounted() {
    const _this = this;
    if (!this.preSelectLocation) {
      this.locations = this.authUser.groups;
      if (this.locations == null || this.locations.length == 0) {
        this.$router.push({
          name: 'settingHome',
        });
      }
    }
    this.setDialogDisplay();
  },
  methods: {

    ...mapActions({
      setGroupId: 'user/setGroupId',
    }),

    close(source) {
      if (source === 'click-outside') {
        return;
      }
      this.show = false;
      this.select.mission = null;
      this.$router.push({
        name: 'dashboard',
      });
    },
    selectMission(missionId) {
      this.select.mission = missionId;
    },
    async assignMission() {
      if (!this.select.mission) {
        this.$showFail('No Location Select');
        return;
      }
      this.setGroupId(this.select.mission);
      const url = this.$route.query.returnUrl;
      if (url && !url.endsWith('selectGroup')) {
        this.$router.push(url);
      } else {
        this.$router.push({ name: 'dashboard' });
      }
    },
    setDialogDisplay() {
      this.show = !this.groupId;
    },
    FormatMissionPeriod,
  },

};
</script>
<style lang="scss">
.group-selection {
  max-height: 574.5px;
  .missions {
    margin-top: 21px;
    margin-left: 20px;
    // margin-right: 40px;
    .mission {
      cursor: pointer;
      float: left;
      width: 176px;
      height: 172px;
      padding-top:12px;
      border-radius: 4px;
      text-align: center;

      position: relative;
      margin-left: 8px;
      span {
        display: block;
        margin-top: 8px;
      }
      img.thumbnail {

        width: 156px;
        height: 104px;
        border: solid 1px #979797;
      }
      img.select {
        display: none;
      }
    }

    .mission:hover {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
    }

    .mission.activate:not(.single) {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
      img.select {
        display: block;
        position: absolute;
        top: -12px;
        right: -12px;

      }
    }

    .mission:first-child:last-child {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.drone-selection {
  line-height: 100px;
  height: 100px;
}
</style>
